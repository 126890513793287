<div class="boxes-area bg-f5f7fa">
    <div class="container" *ngIf="data">
        <div class="row justify-content-center">
            <div class="col-lg-4 col-sm-6 col-md-6" *ngFor="let content of data.bannerBottomBoxes.slice(0, 3)">
                <div class="single-box-item">
                    <div class="icon">
                        <i class="{{content.icon}}"></i>
                        <img src="assets/img/icon-shape.png" alt="image">
                    </div>
                    <h3>{{content.title}}</h3>
                    <p>{{content.desc}}</p>
                    <a routerLink="{{content.link}}" class="link-btn">{{content.linkText}}</a>
                </div>
            </div>
        </div>
    </div>
</div>