<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Checkout</li>
            </ul>
            <h2>Checkout</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape/shape8.svg" alt="image"></div>
</div>

<div *ngIf="!isLoggedIn" class="login-area ptb-100">
    <div class="container text-center">
        <a
            routerLink="/profile-authentication"
            class="default-btn"
        >
            <i class="flaticon-user"></i>
            Please login to continue
            <span></span>
        </a>
    </div>
</div>

<div *ngIf="isLoggedIn" class="checkout-area ptb-100">
    <div class="container">
        <div class="empty-cart" *ngIf="(courses.length == 0); else cartForm">
            <p>Your cart is currently empty.</p>
            <a
                routerLink="/courses-1"
                class="default-btn"
            >
                Return to Courses
                <span></span>
            </a>
        </div>
        <ng-template #cartForm>
            <div class="order-details">
                <h3>Payable Total: ${{total}}</h3>
                <button (click)="makePayment(total)" class="default-btn">Pay ${{total}}</button>
            </div>
        </ng-template>
    </div>
</div>