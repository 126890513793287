<div class="funfacts-area bg-f5f7fa" *ngIf="data">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-6" *ngFor="let content of data.funfactsList.slice(0, 4)">
                <div class="single-funfacts-item">
                    <h3><span [countUp]="content.number">00</span></h3>
                    <p>{{content.title}}</p>
                </div>
            </div>
        </div>
    </div>
</div>