<div class="get-instant-courses-area-two bg-f9fbff" *ngIf="data">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-7 col-md-12">
                <div class="get-instant-courses-content-style-two">
                    <span class="sub-title">{{data.subtitle}}</span>
                    <h2>{{data.title}}</h2>
                    <p>{{data.paragraph}}</p>
                    <a
                        routerLink="{{data.buttonLink}}"
                        class="default-btn"
                    >
                        <i class="{{data.buttonIcon}}"></i>
                        {{data.buttonText}}
                        <span></span>
                    </a>
                </div>
            </div>
            <div class="col-lg-5 col-md-12">
                <div class="get-instant-courses-image-style-two">
                    <img [src]="data.image2.url" alt="image">
                </div>
            </div>
        </div>
    </div>
    <div class="bulb" *ngIf="data.shapeHide"><img src="assets/img/shape/bulb2.png" alt="image"></div>
</div>