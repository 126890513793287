<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Pay Your Bill</li>
            </ul>
            <h2>Pay Your Bill</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape/shape8.svg" alt="image"></div>
</div>

<div *ngIf="!isLoggedIn" class="login-area ptb-100">
    <div class="container text-center">
        <a
            routerLink="/profile-authentication"
            class="default-btn"
        >
            <i class="flaticon-user"></i>
            Please login to continue
            <span></span>
        </a>
    </div>
</div>



<div *ngIf="isLoggedIn"  class="contact-area ptb-100">
    <div class="container">
        <div class="row justify-content-center">

            <div class="col-lg-8 col-md-12">
                <div class="contact-form">
                    <h2>Pay your bill here.</h2>
                    <p>Let us know if you find any difficulties to pay online.</p>
                    <form [formGroup]="contactForm" (ngSubmit)="onSubmit()">
                        <div class="row">
                            <div class="col-lg-12 col-md-6">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Your name" formControlName="name">
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-6">
                                <div class="form-group">
                                    <input type="text" class="form-control"   placeholder="Your email address" formControlName="email">
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <input type="text" class="form-control"  type="number" placeholder="Your phone number" formControlName="phoneNumber">
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <input type="text" class="form-control" type="number" placeholder="Amount you are paying " formControlName="amount">
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <textarea cols="30" rows="5" class="form-control" placeholder="Payment description" formControlName="payment_description"></textarea>
                                </div>
                            </div>


                            <div class="col-lg-12 col-md-12">
                                <button
                                    type="submit"
                                    class="default-btn"
                                    [disabled]="
                                            !contactForm.valid
                                        "
                                >
                                    Pay Bill
                                    <span></span>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
