// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    // API_URL: 'http://localhost:1337',
    // stripe_publish_key:'pk_test_51On2UuEAnYgBppNIYu5RW05y0fuP2Rfp64DCqKvX6CiIHJNguIrLW255KxjCjSUnEb5WcnLI4TwbpV9DWaDA6Ow300aeRKOXo6',
    stripe_publish_key:'pk_live_51On2UuEAnYgBppNInvmngy40RBloZ7jJ8Yop18UDoHD8IybWdXi3bAn2D6sPSbsAfNHfIiQdg4WCMp3hG5PZHx4U006MkySYpp',
    API_URL: 'https://ithelpworldangularnode-node-backend.cluezl.easypanel.host'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
