<div class="slogan-area mtb-100 bg-fffaf3 ptb-100" *ngIf="data">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-12">
                <div class="slogan-image">
                    <img [src]="data.image.url" alt="image">
                </div>
            </div>
            <div class="col-lg-7 col-md-12">
                <div class="slogan-content">
                    <p>{{data.desc}}</p>
                    <h3>{{data.name}}</h3>
                    <span class="sub-title">{{data.designation}}</span>
                </div>
            </div>
        </div>
    </div>
    <div class="divider2"></div>
    <div class="divider3"></div>
    <div class="shape2" *ngIf="data.shapeHide"><img src="assets/img/shape/shape2.png" alt="image"></div>
    <div class="shape3" *ngIf="data.shapeHide"><img src="assets/img/shape/shape3.png" alt="image"></div>
    <div class="shape4" *ngIf="data.shapeHide"><img src="assets/img/shape/shape4.png" alt="image"></div>
    <div class="shape9" *ngIf="data.shapeHide"><img src="assets/img/shape/shape8.svg" alt="image"></div>
</div>