import {Component} from '@angular/core';
import {NotifierService} from "angular-notifier";
import {environment} from "../../../../environments/environment";
import {UntypedFormBuilder, Validators} from "@angular/forms";
import {ContactUsService} from "../contact-us/contact-us.service";
import {HttpClient} from "@angular/common/http";
import {loadStripe} from "@stripe/stripe-js";
import {UserService} from "../../../user.service";

@Component({
    selector: 'app-bill-payment',
    templateUrl: './bill-payment.component.html',
    styleUrls: ['./bill-payment.component.scss']
})
export class BillPaymentComponent {
    private readonly notifier: NotifierService;
    private API_URL = environment.API_URL;
    private stripe_publish_key = environment.stripe_publish_key;
    public data: any;
    userData = this.userService.getUser();
    isLoggedIn = this.userService.isLoggedIn();


    contactForm = this.formBuilder.group({
        name: ['', [Validators.required]],
        email: ['', [Validators.required]],
        phoneNumber: ['', [Validators.required]],
        payment_description: ['', [Validators.required]],
        amount: ['', [Validators.required]],
    });

    constructor(
        private content: ContactUsService,
        private formBuilder: UntypedFormBuilder,
        private http: HttpClient,
        notifierService: NotifierService,
        private userService: UserService,
    ) {
        // this.content.getData().subscribe((data: any) => {
        //     this.data = data;
        // });
        // this.notifier = notifierService;
    }

    ngOnInit(): void {
    }

    onSubmit(): void {
        this.makePayment('');
    }

    makePayment(amount: any) {
        debugger;
        let formValue = this.contactForm.value;
        const paymentDetails = {
            formValue: formValue,
            user: this.userData
        };
        let billPaymentUrl = `${this.API_URL}/monthlybillpayments/billcheckout`;

        this.http
            .post<any>(billPaymentUrl, paymentDetails)
            .subscribe(async (res: any) => {
                let stripe = await loadStripe(this.stripe_publish_key);
                stripe?.redirectToCheckout({
                        sessionId: res.id
                    },
                )
            });

    }


}
