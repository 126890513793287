<div class="categories-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Categories</span>
            <h2>Top Categories</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>
        <div class="row" *ngIf="data">
            <div class="col-lg-3 col-sm-6 col-md-6" *ngFor="let content of data.slice(0, 8);">
                <div class="single-categories-box">
                    <img [src]="content.image.url" alt="image">
                    <div class="content">
                        <h3>{{content.title}}</h3>
                        <span>{{content.courses.length}} Courses</span>
                    </div>
                    <a
                        [routerLink]="['/course-category', content.slug]"
                        class="link-btn"
                    ></a>
                </div>
            </div>
            <div class="col-lg-12 col-sm-12 col-md-12">
                <div class="categories-btn-box">
                    <a routerLink="/categories" class="default-btn"><i class="flaticon-user"></i>View All Categories<span></span></a>
                </div>
            </div>
        </div>
    </div>
</div>