<div class="blog-area pt-100 pb-70">
    <div class="container">
        <div class="section-title" *ngIf="blogData">
            <span class="sub-title">{{blogData.subtitle}}</span>
            <h2 class="playfair-display-font">{{blogData.title}}</h2>
            <p>{{blogData.paragraph}}</p>
        </div>
        <div class="row justify-content-center" *ngIf="data">
            <div class="col-lg-4 col-md-6" *ngFor="let content of data.slice(0, 3)">
                <div class="single-blog-post-box">
                    <div class="post-image">
                        <a
                            [routerLink]="['/blog', content.slug]"
                            class="d-block"
                        >
                            <img [src]="content.image.url" alt="image">
                        </a>
                    </div>
                    <div class="post-content">
                        <a
                            [routerLink]="['/category', content.blog_category.slug]"
                            class="category"
                        >
                            {{content.blog_category.title}}
                        </a>
                        <h3 class="playfair-display-font">
                            <a 
                                [routerLink]="['/blog', content.slug]"
                            >
                                {{content.title}}
                            </a>
                        </h3>
                        <span class="date"><i class='flaticon-calendar'></i> {{content.published_at | date}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>