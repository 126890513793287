<div class="teacher-register-area ptb-100">
    <div class="container">
        <div class="teacher-register-box">
            <h2>Register to Become an Intructor</h2>
            <p>Your email address will not be published. Required fields are marked *</p>
            <form [formGroup]="contactForm" (ngSubmit)="onSubmit()">
                <div class="form-group">
                    <input type="text" class="form-control" placeholder="Your Name *" formControlName="name">
                </div>
                <div class="form-group">
                    <input type="text" class="form-control" placeholder="Your Email *" formControlName="email">
                </div>
                <div class="form-group">
                    <input type="text" class="form-control" placeholder="Your Phone *" formControlName="phoneNumber">
                </div>
                <div class="form-group">
                    <textarea cols="30" rows="5" class="form-control" placeholder="Your Message *" formControlName="message"></textarea>
                </div>
                <button
                    type="submit"
                    class="default-btn"
                    [disabled]="
                        !contactForm.valid
                    "
                >
                    Submit Now
                    <span></span>
                </button>
            </form>
        </div>
    </div>
</div>