<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Welcome</li>
            </ul>
            <h2>Welcome to ITHelpWorld</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape/shape8.svg" alt="image"></div>
</div>

<div class="profile-authentication-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="login-form">
                    <h2>Login</h2>
                    <form
                        [formGroup]="logForm"
                        (ngSubmit)="onLogin()"
                    >
                        <div class="form-group">
                            <label>Email</label>
                            <input type="text" class="form-control" placeholder="Email" formControlName="identifier">
                        </div>
                        <div class="form-group">
                            <label>Password</label>
                            <input type="password" class="form-control" placeholder="Password" formControlName="password">
                        </div>
                        <button type="submit">Log In</button>
                    </form>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="register-form">
                    <h2>Register</h2>
                    <form
                        [formGroup]="regForm"
                        (ngSubmit)="onRegister()"
                    >
                        <div class="form-group">
                            <label>Name</label>
                            <input type="text" class="form-control" placeholder="Name" formControlName="username">
                        </div>
                        <div class="form-group">
                            <label>Email</label>
                            <input type="email" class="form-control" placeholder="Email" formControlName="email">
                        </div>
                        <div class="form-group">
                            <label>Password</label>
                            <input type="password" class="form-control" placeholder="Password" formControlName="password">
                        </div>
                        <button type="submit">Register</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
