<div class="pricing-area bg-f9f9f9 pt-100 pb-70" *ngIf="data">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">{{data.subtitle}}</span>
            <h2 class="playfair-display-font">{{data.title}}</h2>
            <p>{{data.paragraph}}</p>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6" *ngFor="let content of data.pricing;">
                <div class="single-pricing-box">
                    <div class="pricing-header">
                        <img [src]="content.image.url" alt="image">
                        <h3>{{content.title}}</h3>
                    </div>
                    <div class="pricing-features">
                        <ul>
                            <li *ngFor="let item of content.features">
                                {{item.title}}
                            </li>
                        </ul>
                    </div>
                    <div class="price">
                        ${{content.price}}
                        <span>{{content.subText}}</span>
                    </div>
                    <a routerLink="{{content.buttonLink}}" class="default-btn">{{content.buttonText}}<span></span></a>
                    <div class="pricing-shape1"><img src="assets/img/shape/pricing-shape1.png" alt="image"></div>
                    <div class="pricing-shape2"><img src="assets/img/shape/pricing-shape2.png" alt="image"></div>
                </div>
            </div>
        </div>
    </div>
</div>