<footer class="footer-area" *ngIf="data">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <a routerLink="/" class="logo">
                        <img [src]="data.logo.url" alt="logo">
                    </a>
                    <p>{{data.desc}}</p>
                    <ul class="social-link">
                        <li *ngFor="let item of data.socials">
                            <a href="{{item.link}}" class="d-block" target="_blank">
                                <i class='{{item.icon}}'></i>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6" *ngFor="let content of data.widgets.slice(0, 2)">
                <div class="single-footer-widget">
                    <h3>{{content.title}}</h3>
                    <ul class="footer-links-list">
                        <li *ngFor="let item of content.allItems">
                            <a routerLink="{{item.link}}">
                                {{item.linkText}}
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>{{data.title}}</h3>
                    <ul class="footer-contact-info">
                        <li><i class='bx bx-map'></i> {{data.location}}</li>
                        <li><i class='bx bx-phone-call'></i> <a href="tel:{{data.phoneNumber}}">{{data.phoneNumber}}</a></li>
                        <li><i class='bx bx-envelope'></i> <a href="mailto:{{data.email}}">{{data.email}}</a></li>
<!--                        <li><i class='bx bxs-inbox'></i> <a href="tel:{{data.faxNumber}}">{{data.faxNumber}}</a></li>-->
                    </ul>
                </div>
            </div>
        </div>
        <div class="footer-bottom-area">
            <div markdown ngPreserveWhitespaces>
                {{data.copyright}}
            </div>
        </div>
    </div>
    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</footer>
