<div class="about-area-two bg-fffaf3 pt-70 pb-100" *ngIf="data">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-12">
                <div class="about-content-box">
                    <span class="sub-title">{{data.subtitle}}</span>
                    <h2>{{data.title}}</h2>
                    <p>{{data.desc}}</p>
                    <p><strong>{{data.subtext}}</strong></p>
                    <a routerLink="{{data.link}}" class="link-btn">{{data.linkText}}</a>
                </div>
            </div>
            <div class="col-lg-7 col-md-12">
                <div class="about-video-box">
                    <div class="image">
                        <img [src]="data.image.url" alt="image">
                    </div>
<!--                    <a-->
<!--                        href="{{data.videoLink}}"-->
<!--                        class="video-btn"-->
<!--                        target="_blank"-->
<!--                    >-->
<!--                        <i class="flaticon-play"></i>-->
<!--                    </a>-->
                    <div class="shape10" *ngIf="data.shapeHide"><img src="assets/img/shape/shape9.png" alt="image"></div>
                </div>
            </div>
        </div>
    </div>
    <div class="divider"></div>
    <div class="shape3" *ngIf="data.shapeHide"><img src="assets/img/shape/shape3.png" alt="image"></div>
    <div class="shape4" *ngIf="data.shapeHide"><img src="assets/img/shape/shape4.png" alt="image"></div>
    <div class="shape2" *ngIf="data.shapeHide"><img src="assets/img/shape/shape2.png" alt="image"></div>
</div>
