<div class="feedback-with-bg-image ptb-100" *ngIf="data" style="background-image: url({{data.bgImage.url}});">
    <div class="container">
        <div class="feedback-slides feedback-slides-style-two">
            <owl-carousel-o [options]="feedbackSlides">
                <ng-template carouselSlide *ngFor="let content of data.allFeedback">
                    <div class="single-feedback-item-box">
                        <p>{{content.feedbackQuote}}</p>
                        <div class="client-info d-flex align-items-center">
                            <img [src]="content.authorImage.url" class="rounded-circle" alt="image">
                            <div class="title">
                                <h3>{{content.authorName}}</h3>
                                <span>{{content.authorDesignation}}</span>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
</div>