<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Cart</li>
            </ul>
            <h2>Cart</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape/shape8.svg" alt="image"></div>
</div>

<div class="cart-area ptb-100">
    <div class="container">
        <div class="empty-cart" *ngIf="products.length == 0; else cartForm">
            <p>Your cart is currently empty.</p>
            <a routerLink="/products-list-2" class="default-btn">
                Return to Shop
                <span></span>
            </a>
        </div>
        <ng-template #cartForm>
            <form>
                <div class="cart-table table-responsive">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th scope="col">Product</th>
                                <th scope="col">Name</th>
                                <th scope="col">Unit Price</th>
                                <th scope="col">Quantity</th>
                                <th scope="col">Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let product of products;">
                                <td class="product-thumbnail">
                                    <a
                                        [routerLink]="[
                                            '/product',
                                            product.slug
                                        ]"
                                    >
                                        <img [src]="product.image.url" alt="item">
                                    </a>
                                </td>
                                <td class="product-name">
                                    <a
                                        [routerLink]="[
                                            '/product',
                                            product.slug
                                        ]"
                                    >
                                        {{product.title}}
                                    </a>
                                </td>
                                <td class="product-price">
                                    <span class="unit-amount">${{product.price}}</span>
                                </td>
                                <td class="product-quantity">
                                    <div class="input-counter">
                                        <span
                                            class="minus-btn"
                                            (click)="
                                                minus(product.id)
                                            "
                                        >
                                            <i class='bx bx-minus'></i>
                                        </span>
                                        <input
                                            type="text"
                                            value="{{
                                                product.quantity
                                            }}"
                                        />
                                        <span
                                            class="plus-btn"
                                            (click)="
                                                plus(product.id)
                                            "
                                        >
                                            <i class='bx bx-plus'></i>
                                        </span>
                                    </div>
                                </td>
                                <td class="product-subtotal">
                                    <span class="subtotal-amount">
                                        ${{
                                            (
                                                product.quantity *
                                                product.price
                                            ).toFixed(2)
                                        }}
                                    </span>
                                    <button
                                        (click)="
                                            onDeleteItem(product.id)
                                        "
                                        class="remove"
                                    >
                                        <i class='bx bx-trash'></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="cart-totals">
                    <h3>Cart Totals</h3>
                    <ul>
                        <li>Subtotal <span>${{total}}</span></li>
                        <li>Shipping <span>$00.00</span></li>
                        <li>Total <span>${{total}}</span></li>
                    </ul>
                    <a
                        routerLink="/checkout"
                        class="default-btn"
                    >
                        <i class="flaticon-shopping-cart"></i>
                        Proceed to Checkout
                        <span></span>
                    </a>
                </div>
            </form>
        </ng-template>
    </div>
</div>
