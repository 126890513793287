<div *ngIf="data">

    <div class="page-title-area">
        <div class="container">
            <div class="page-title-content">
                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li>{{data.pageBannerTitle}}</li>
                </ul>
                <h2>{{data.pageBannerTitle}}</h2>
            </div>
        </div>
        <div class="shape9"><img src="assets/img/shape/shape8.svg" alt="image"></div>
    </div>

    <div class="gallery-area pt-100 pb-70">
        <div class="container">
            <lightgallery class="row" [settings]="settings" [onBeforeSlide]="onBeforeSlide">
                <a href="{{item.image.url}}" class="single-gallery-item col-lg-4 col-md-6 col-sm-6" *ngFor="let item of data.images;">
                    <img [src]="item.image.url" alt="image">
                </a>
            </lightgallery>
        </div>
    </div>

</div>