<app-yoga-banner></app-yoga-banner>

<app-training></app-training>

<app-yoga-about></app-yoga-about>

<app-yoga-courses></app-yoga-courses>

<app-feedback-style-two></app-feedback-style-two>

<app-experience></app-experience>

<div class="bg-fffaf3">
    <app-funfacts-style-one></app-funfacts-style-one>
</div>

<app-download-syllabus></app-download-syllabus>

<app-flexible-pricing></app-flexible-pricing>

<app-yoga-blog></app-yoga-blog>

<div class="subscribe-area bg-f5f7fa ptb-100">
    <app-subscribe-style-two></app-subscribe-style-two>
    <div class="shape4"><img src="assets/img/shape/shape4.png" alt="image"></div>
    <div class="shape13"><img src="assets/img/shape/shape12.png" alt="image"></div>
</div>

<div class="partner-area bg-6ba292 ptb-70">
    <div class="container">
        <app-partner></app-partner>
    </div>
</div>