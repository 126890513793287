<div *ngIf="data">
    <div *ngFor="let content of data;">

        <div class="page-title-area">
            <div class="container">
                <div class="page-title-content">
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Courses</li>
                        <li>Category: {{content.title}}</li>
                    </ul>
                    <h2>Category: {{content.title}}</h2>
                </div>
            </div>
            <div class="shape9"><img src="assets/img/shape/shape8.svg" alt="image"></div>
        </div>

        <div class="courses-area pt-100 pb-70">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-4 col-md-6" *ngFor="let text of content.courses | paginate: { itemsPerPage: 12, currentPage: coursesGrid }">
                        <div class="single-courses-box">
                            <div class="courses-image">
                                <a
                                    [routerLink]="['/course', text.slug]"
                                    class="d-block image"
                                >
                                    <img [src]="text.image.url" alt="image">
                                </a>
                                <div class="price shadow">${{text.price}}</div>
                            </div>
                            <div class="courses-content">
                                <div class="course-author d-flex align-items-center">
                                    <img src="assets/img/avatar.jpg" class="rounded-circle" alt="image">
                                    <span *ngIf="content.admin_user">{{content.admin_user.firstname}} {{content.admin_user.lastname}}</span>
                                </div>
                                <h3>
                                    <a
                                        [routerLink]="['/course', text.slug]"
                                    >
                                        {{text.title}}
                                    </a>
                                </h3>
                                <p>{{text.shortDesc}}</p>
                                <ul class="courses-box-footer d-flex justify-content-between align-items-center">
                                    <li *ngIf="text.numberOfLesson"><i class='flaticon-agenda'></i> {{text.numberOfLesson}} Lessons</li>
                                    <li *ngIf="text.numberOfStudent"><i class='flaticon-people'></i> {{text.numberOfStudent}} Students</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12" *ngIf="content.courses?.length <= 0">
                        <div class="text-center mb-30">
                            <p>No course for you in this category.</p>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12" *ngIf="content.courses?.length > 12">
                        <div class="pagination-area text-center">
                            <pagination-controls previousLabel="" nextLabel="" (pageChange)="coursesGrid = $event"></pagination-controls>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>