<div class="courses-area bg-image ptb-100" *ngFor="let Image of bgImage;" style="background-image: url({{Image.img}});">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Learn At Your Own Pace</span>
            <h2 class="playfair-display-font">My Recent Courses</h2>
            <p>Explore all of our courses and pick your suitable ones to enroll and start learning with us! We ensure that you will never regret it!</p>
        </div>
        <div class="row justify-content-center" *ngIf="data">
            <div class="col-lg-4 col-md-6" *ngFor="let content of data.slice(0, 6)">
                <div class="single-courses-box">
                    <div class="courses-image">
                        <a
                            [routerLink]="['/course', content.slug]"
                            class="d-block image"
                        >
                            <img [src]="content.image.url" alt="image">
                        </a>
                        <div class="price shadow">${{content.price}}</div>
                        <button
                            type="submit"
                            class="default-btn"
                            (click)="addToCart(content)"
                        >
                            Add To Cart
                            <span></span>
                        </button>
                    </div>
                    <div class="courses-content">
                        <div class="course-author d-flex align-items-center">
                            <img src="assets/img/avatar.jpg" class="rounded-circle" alt="image">
                            <span *ngIf="content.admin_user">{{content.admin_user.firstname}} {{content.admin_user.lastname}}</span>
                        </div>
                        <h3 class="playfair-display-font">
                            <a
                                [routerLink]="['/course', content.slug]"
                            >
                                {{content.title}}
                            </a>
                        </h3>
                        <div markdown ngPreserveWhitespaces>
                            {{content.shortDescription}}
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-12 col-md-12">
                <div class="courses-info">
                    <p>Enjoy the top notch learning methods and achieve next level skills! You are the creator of your own career & we will guide you through that. <a routerLink="/profile-authentication">Register Free Now!</a>.</p>
                </div>
            </div>
        </div>
    </div>
</div>
