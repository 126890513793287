<div class="about-area bg-fef8ef ptb-100" *ngIf="data">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-image">
                    <div class="row">
                        <div class="col-lg-6 col-sm-6 col-md-6 col-6" *ngFor="let image of data.aboutImages">
                            <div class="image">
                                <img [src]="image.image.url" alt="image">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <span class="sub-title">{{data.subtitle}}</span>
                    <h2>{{data.title}}</h2>
                    <p>{{data.paragraph}}</p>
                    <ul class="features-list">
                        <li *ngFor="let item of data.featuresList">
                            <span>
                                <i class="{{item.icon}}"></i> {{item.title}}
                            </span>
                        </li>
                    </ul>
                    <a routerLink="{{data.buttonLink}}" class="default-btn">
                        <i class="{{data.buttonIcon}}"></i>
                        {{data.buttonText}}
                        <span></span>
                    </a>
                </div>
            </div>
        </div>
    </div>
    <div class="shape1" *ngIf="data.shapeHide"><img src="assets/img/shape/shape1.png" alt="image"></div>
    <div class="shape2" *ngIf="data.shapeHide"><img src="assets/img/shape/shape2.png" alt="image"></div>
    <div class="shape3" *ngIf="data.shapeHide"><img src="assets/img/shape/shape3.png" alt="image"></div>
    <div class="shape4" *ngIf="data.shapeHide"><img src="assets/img/shape/shape4.png" alt="image"></div>
</div>