<div *ngIf="data">
    <div *ngFor="let content of data;">

        <div class="page-title-area">
            <div class="container">
                <div class="page-title-content">
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>
                            <a
                                [routerLink]="['/category', content.courses_category.slug]"
                            >
                                {{content.courses_category.title}}
                            </a>
                        </li>
                        <li>{{content.title}}</li>
                    </ul>
                    <h2>{{content.title}}</h2>
                </div>
            </div>
            <div class="shape9"><img src="assets/img/shape/shape8.svg" alt="image"></div>
        </div>

        <div class="courses-details-area ptb-100">
            <div class="container">
                <div class="row">
                    <div class="col-lg-8 col-md-12">
                        <div class="courses-details-image">
                            <img [src]="content.image.url" alt="image">
                        </div>
                        <div class="courses-details-desc">
                            <div markdown ngPreserveWhitespaces>
                                {{content.description}}
                            </div>
<!--                            <h3>Courses Video</h3>-->
<!--                            <div class="courses-curriculum">-->
<!--                                <ul>-->
<!--                                    <li *ngFor="let item of content.course_videos;">-->
<!--                                        <a href="#" class="locked popup-youtube d-flex justify-content-between align-items-center">-->
<!--                                            <span class="courses-name">{{item.title}}</span>-->
<!--                                            <div class="courses-meta">-->
<!--                                                <span class="duration">{{item.duration}}</span>-->
<!--                                                <span class="status locked"><i class="flaticon-password"></i></span>-->
<!--                                            </div>-->
<!--                                        </a>-->
<!--                                    </li>-->
<!--                                </ul>-->
<!--                            </div>-->
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-12">
                        <div class="courses-sidebar-information">
                            <ul class="info">
<!--                                <li *ngIf="content.admin_user">-->
<!--                                    <div class="d-flex justify-content-between align-items-center">-->
<!--                                        <span><i class="flaticon-teacher"></i> Instructor</span>-->
<!--                                        {{content.admin_user.firstname}} {{content.admin_user.lastname}}-->
<!--                                    </div>-->
<!--                                </li>-->
                                <li>
                                    <div class="d-flex justify-content-between align-items-center">
                                        <span><i class="flaticon-time"></i> Duration</span>
                                        {{content.totalCourseDuration}}
                                    </div>
                                </li>
<!--                                <li *ngIf="content.numberOfLesson">-->
<!--                                    <div class="d-flex justify-content-between align-items-center">-->
<!--                                        <span><i class="flaticon-distance-learning"></i> Lessons</span>-->
<!--                                        {{content.numberOfLesson}}-->
<!--                                    </div>-->
<!--                                </li>-->
                                <li>
                                    <div class="d-flex justify-content-between align-items-center">
                                        <span><i class="flaticon-html"></i> Language</span>
                                        {{content.language}}
                                    </div>
                                </li>
<!--                                <li>-->
<!--                                    <div class="d-flex justify-content-between align-items-center">-->
<!--                                        <span><i class="flaticon-caption"></i> Video Subtitle</span>-->
<!--                                        {{content.videoSubtitle}}-->
<!--                                    </div>-->
<!--                                </li>-->
<!--                                <li>-->
<!--                                    <div class="d-flex justify-content-between align-items-center">-->
<!--                                        <span><i class="flaticon-lock"></i> Access</span>-->
<!--                                        {{content.accessLimit}}-->
<!--                                    </div>-->
<!--                                </li>-->
                                <li>
                                    <div class="d-flex justify-content-between align-items-center">
                                        <span><i class="flaticon-certification"></i> Certificate</span>
                                        <span *ngIf="content.certificate == 1; else noCertificate">
                                            Yes
                                        </span>
                                        <ng-template #noCertificate>
                                            No
                                        </ng-template>
                                    </div>
                                </li>
                            </ul>
                            <div class="btn-box">
                                <div class="price">${{content.price}}</div>
                                <button
                                    type="submit"
                                    class="default-btn"
                                    (click)="addToCart(content)"
                                >
                                    <i class="flaticon-shopping-cart"></i>
                                    Add To Cart
                                    <span></span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>

<div class="courses-area bg-f8f9f8 pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>More Packages You Might Like</h2>
        </div>
        <div class="row justify-content-center" *ngIf="courseData">
            <div class="col-lg-4 col-md-6" *ngFor="let content of courseData.slice(0, 3)">
                <div class="single-courses-box">
                    <div class="courses-image">
                        <a
                            [routerLink]="['/course', content.slug]"
                            class="d-block image"
                        >
                            <img [src]="content.image.url" alt="image">
                        </a>
                        <div class="price shadow">${{content.price}}</div>
                        <button
                            type="submit"
                            class="default-btn"
                            (click)="addToCart(content)"
                        >
                            Add To Cart
                            <span></span>
                        </button>
                    </div>
                    <div class="courses-content">
<!--                        <div class="course-author d-flex align-items-center">-->
<!--                            <img src="assets/img/avatar.jpg" class="rounded-circle" alt="image">-->
<!--                            <span *ngIf="content.admin_user">{{content.admin_user.firstname}} {{content.admin_user.lastname}}</span>-->
<!--                        </div>-->
                        <h3>
                            <a
                                [routerLink]="['/course', content.slug]"
                            >
                                {{content.title}}
                            </a>
                        </h3>
<!--                        <p>{{content.shortDesc}}</p>-->
                        <div markdown ngPreserveWhitespaces>
                            {{content.shortDescription}}
                        </div>
                        <ul class="courses-box-footer d-flex justify-content-between align-items-center">
                            <li *ngIf="content.totalCourseDuration"><i class='flaticon-agenda'></i> {{content.totalCourseDuration}}</li>
                            <li *ngIf="content.numberOfStudent"><i class='flaticon-people'></i> {{content.numberOfStudent}} Students</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
