<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Products</li>
            </ul>
            <h2>Shop</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape/shape8.svg" alt="image"></div>
</div>

<div class="products-area ptb-100">
    <div class="container" *ngIf="data">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="edva-grid-sorting row align-items-center">
                    <div class="col-lg-6 col-md-6 result-count">
                        <p>We found <span class="count">{{data.length}}</span> products available for you</p>
                    </div>
                    <div class="col-lg-6 col-md-6 ordering">
                        <div class="select-box">
                            <label>Sort By:</label>
                            <select>
                                <option>Default</option>
                                <option>Popularity</option>
                                <option>Latest</option>
                                <option>Price: low to high</option>
                                <option>Price: high to low</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-6" *ngFor="let content of data | paginate: { itemsPerPage: 6, currentPage: shopGrid }">
                        <div class="single-products-box">
                            <div class="products-image">
                                <a
                                    [routerLink]="['/product', content.slug]"
                                >
                                    <img [src]="content.image.url" class="main-image" alt="image">
                                </a>
                            </div>
                            <div class="products-content">
                                <h3>
                                    <a
                                        [routerLink]="['/product', content.slug]"
                                    >
                                        {{content.title}}
                                    </a>
                                </h3>
                                <div class="price">
                                    <span class="new-price">${{content.price}}</span>
                                </div>
                                <button
                                    class="add-to-cart"
                                    (click)="addToCart(content)"
                                    *ngIf="content.availability"
                                >
                                    Add to Cart
                                </button>
                                <button
                                    class="add-to-cart out"
                                    *ngIf="!content.availability"
                                >
                                    Out of Stock
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12" *ngIf="data?.length > 6">
                        <div class="pagination-area text-center">
                            <pagination-controls previousLabel="" nextLabel="" (pageChange)="shopGrid = $event"></pagination-controls>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <aside class="widget-area">
                    <section class="widget widget_search">
                        <h3 class="widget-title">Search</h3>
                        <div class="search-form" [class.active]="classSearchApplied">
                            <input type="text" [(ngModel)]="term" class="search-field" placeholder="Search products..." (click)="togglSearcheClass()">
                            <button type="submit"><i class="bx bx-search-alt"></i></button>
                            <div class="products-lists" *ngIf="data">
                                <div class="list-group-item" *ngFor="let content of data | filter : term">
                                    <div class="row align-items-center" (click)="togglSearcheClass()">
                                        <div class="col-lg-3 col-md-3">
                                            <a 
                                                [routerLink]="['/product', content.slug]"
                                                class="products-img"
                                            >
                                                <img [src]="content.image.url" alt="Image">
                                            </a>
                                        </div>
                                        <div class="col-lg-9 col-md-9">
                                            <div class="info">
                                                <h3>
                                                    <a 
                                                        [routerLink]="['/product', content.slug]"
                                                    >
                                                        {{content.title}}
                                                    </a>
                                                </h3>
                                                <span class="price">${{content.price}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section class="widget widget_popular_products">
                        <h3 class="widget-title">Recent Products</h3>
                        <article class="item" *ngFor="let content of data.slice(0, 5)">
                            <a
                                [routerLink]="['/product', content.slug]"
                                class="thumb"
                            >
                                <span class="fullimage cover" style="background-image: url({{content.image.url}});" role="img"></span>
                            </a>
                            <div class="info">
                                <span>${{content.price}}</span>
                                <h4 class="title usmall">
                                    <a
                                        [routerLink]="['/product', content.slug]"
                                    >
                                        {{content.title}}
                                    </a>
                                </h4>
                                <a
                                    [routerLink]="['/product', content.slug]"
                                    class="add-to-cart"
                                >
                                    View Details
                                </a>
                            </div>
                            <div class="clear"></div>
                        </article>
                    </section>
                </aside>
            </div>
        </div>
    </div>
</div>