<div class="banner-wrapper-area" *ngIf="data">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="banner-wrapper-content">
                    <h1>{{data.title}}</h1>
                    <p>{{data.desc}}</p>
                    <form>
                        <label><i class="flaticon-search"></i></label>
                        <input type="text" class="input-search" placeholder="What do you want to learn?">
                        <button type="submit">Search Now</button>
                    </form>
                    <ul class="popular-search-list">
                        <li><span>{{data.subText}}</span></li>
                        <li *ngFor="let item of data.popularSearch.slice(0, 5)">
                            <a routerLink="{{item.link}}">{{item.title}}</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="banner-wrapper-image">
                    <img [src]="data.image.url" alt="image">
                    <div class="banner-shape8" *ngIf="data.shapeHide"><img src="assets/img/shape/banner-shape8.png" alt="image"></div>
                    <div class="banner-shape9" *ngIf="data.shapeHide"><img src="assets/img/shape/banner-shape9.png" alt="image"></div>
                    <div class="banner-shape10" *ngIf="data.shapeHide"><img src="assets/img/shape/banner-shape10.png" alt="image"></div>
                </div>
            </div>
        </div>
        <div class="banner-inner-area">
            <div class="row justify-content-center">
                <div class="col-lg-4 col-md-6 col-sm-6" *ngFor="let content of data.bannerBottomBoxes.slice(0, 3)">
                    <div class="single-banner-box">
                        <div class="icon">
                            <i class="{{content.icon}}"></i>
                        </div>
                        <h3>{{content.title}}</h3>
                        <p>{{content.desc}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="divider"></div>
</div>