<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Update Profile</li>
            </ul>
            <h2>Update Profile</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape/shape8.svg" alt="image"></div>
</div>

<div class="profile-area pt-100 pb-70">
    <div class="container">
        <div class="profile-box">
            <h3>My Information</h3>
            <form [formGroup]="updateForm" (ngSubmit)="onSubmit()">
                <div class="row">
                    <div class="col-lg-6 col-sm-6">
                        <div class="form-group">
                            <label>Name</label>
                            <input type="text" class="form-control" [(ngModel)]="userData.username" [value]="userData.username" formControlName="username">
                            <div *ngIf="f.username.touched && f.username.invalid" class="alert alert-danger">
                                <div *ngIf="f.username.errors && f.username.errors.required">Name is required.</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-sm-6">
                        <div class="form-group">
                            <label>Email</label>
                            <input type="email" class="form-control" [(ngModel)]="userData.email" [value]="userData.email" formControlName="email">
                            <div *ngIf="f.email.touched && f.email.invalid" class="alert alert-danger">
                                <div *ngIf="f.email.errors && f.email.errors.required">Email is required.</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-sm-12">
                        <div class="form-group">
                            <label>Phone Number</label>
                            <input type="text" class="form-control" [(ngModel)]="userData.phoneNumber" [value]="userData.phoneNumber" formControlName="phoneNumber">
                            <div *ngIf="f.phoneNumber.touched && f.phoneNumber.invalid" class="alert alert-danger">
                                <div *ngIf="f.phoneNumber.errors && f.phoneNumber.errors.required">Phone number is required.</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12">
                        <div class="form-group">
                            <label>Biography</label>
                            <textarea class="form-control" rows="7" [(ngModel)]="userData.biography" [value]="userData.biography" formControlName="biography"></textarea>
                            <div *ngIf="f.biography.touched && f.biography.invalid" class="alert alert-danger">
                                <div *ngIf="f.biography.errors && f.biography.errors.required">Biography is required.</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="form-group">
                            <label>Twitter</label>
                            <input type="text" class="form-control" [(ngModel)]="userData.twitter" [value]="userData.twitter" formControlName="twitter">
                            <div *ngIf="f.twitter.touched && f.twitter.invalid" class="alert alert-danger">
                                <div *ngIf="f.twitter.errors && f.twitter.errors.required">Twitter is required.</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="form-group">
                            <label>Facebook</label>
                            <input type="text" class="form-control" [(ngModel)]="userData.facebook" [value]="userData.facebook" formControlName="facebook">
                            <div *ngIf="f.facebook.touched && f.facebook.invalid" class="alert alert-danger">
                                <div *ngIf="f.facebook.errors && f.facebook.errors.required">Facebook is required.</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="form-group">
                            <label>LinkedIn</label>
                            <input type="text" class="form-control" [(ngModel)]="userData.linkedin" [value]="userData.linkedin" formControlName="linkedin">
                            <div *ngIf="f.linkedin.touched && f.linkedin.invalid" class="alert alert-danger">
                                <div *ngIf="f.linkedin.errors && f.linkedin.errors.required">Linkedin is required.</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="form-group">
                            <label>Instagram</label>
                            <input type="text" class="form-control" [(ngModel)]="userData.instagram" [value]="userData.instagram" formControlName="instagram">
                            <div *ngIf="f.instagram.touched && f.instagram.invalid" class="alert alert-danger">
                                <div *ngIf="f.instagram.errors && f.instagram.errors.required">Instagram is required.</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-13">
                        <div class="form-group">
                            <button
                                type="submit"
                                class="default-btn"
                                [disabled]="!updateForm.valid"
                            >
                                Update Profile
                                <span></span>
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>