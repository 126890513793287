<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>About Us</li>
            </ul>
            <h2>About Us</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape/shape8.svg" alt="image"></div>
</div>

<app-view-all-courses-two></app-view-all-courses-two>


<!--<div class="about-area-three ptb-100" *ngIf="data">-->
<!--    <div class="container">-->
<!--        <div class="row align-items-center">-->
<!--            <div class="col-lg-5 col-md-12">-->
<!--                <div class="about-content-box">-->
<!--                    <span class="sub-title">{{data.subtitle}}</span>-->
<!--                    <h2>{{data.title}}</h2>-->
<!--                    <p>{{data.paragraph}}</p>-->
<!--                    <p><strong>{{data.subText}}</strong></p>-->
<!--                    <a-->
<!--                        routerLink="{{data.buttonLink}}"-->
<!--                        class="default-btn"-->
<!--                    >-->
<!--                        <i class="{{data.buttonIcon}}"></i>-->
<!--                        {{data.buttonText}}-->
<!--                        <span></span>-->
<!--                    </a>-->
<!--                </div>-->
<!--            </div>-->
<!--            <div class="col-lg-7 col-md-12">-->
<!--                <div class="about-img">-->
<!--                    <div class="image">-->
<!--                        <img [src]="data.image1.url" alt="image">-->
<!--                        <img [src]="data.image2.url" alt="image">-->
<!--                    </div>-->
<!--                    <div class="shape17" *ngIf="data.shapeHide"><img src="assets/img/shape/shape16.png" alt="image"></div>-->
<!--                    <div class="shape18" *ngIf="data.shapeHide"><img src="assets/img/shape/shape17.png" alt="image"></div>-->
<!--                    <div class="shape19" *ngIf="data.shapeHide"><img src="assets/img/shape/shape18.png" alt="image"></div>-->
<!--                    <div class="shape20" *ngIf="data.shapeHide"><img src="assets/img/shape/shape19.png" alt="image"></div>-->
<!--                    <div class="shape21" *ngIf="data.shapeHide"><img src="assets/img/shape/shape20.png" alt="image"></div>-->
<!--                    <div class="shape22" *ngIf="data.shapeHide"><img src="assets/img/shape/shape21.png" alt="image"></div>-->
<!--                    <div class="shape23" *ngIf="data.shapeHide"><img src="assets/img/shape/shape22.png" alt="image"></div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
<!--    <div class="shape3" *ngIf="data.shapeHide"><img src="assets/img/shape/shape3.png" alt="image"></div>-->
<!--    <div class="shape4" *ngIf="data.shapeHide"><img src="assets/img/shape/shape4.png" alt="image"></div>-->
<!--</div>-->

<app-funfacts-style-two></app-funfacts-style-two>

<div class="partner-area pt-70 pb-100">
    <div class="container">
        <app-partner></app-partner>
    </div>
</div>

<app-testimonials></app-testimonials>
<!--<app-feedback></app-feedback>-->

<app-instructor></app-instructor>

<!--<div class="premium-access-area bg-f9f9f9 ptb-100">-->
<!--    <app-premium-access></app-premium-access>-->
<!--    <div class="shape3"><img src="assets/img/shape/shape3.png" alt="image"></div>-->
<!--    <div class="shape4"><img src="assets/img/shape/shape4.png" alt="image"></div>-->
<!--    <div class="shape8"><img src="assets/img/shape/shape7.png" alt="image"></div>-->
<!--</div>-->
