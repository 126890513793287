<div class="training-area bg-f5f7fa pt-100 pb-70" *ngIf="data">
    <div class="container">
        <div class="section-title">
            <h2 class="playfair-display-font">{{data.title}}</h2>
            <p>{{data.paragraph}}</p>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 col-sm-6" *ngFor="let content of data.allTtraining">
                <div class="single-training-box">
                    <img [src]="content.image.url" alt="image">
                    <h3 class="playfair-display-font">
                        <a routerLink="{{content.link}}">
                            {{content.title}}
                        </a>
                    </h3>
                    <p>{{content.paragraph}}</p>
                    <a routerLink="{{content.link}}" class="link-btn">{{content.linkText}}</a>
                </div>
            </div>
        </div>
    </div>
    <div class="tree-shape2" *ngIf="data.shapeHide"><img src="assets/img/shape/tree-shape2.png" alt="image"></div>
    <div class="tree-shape3" *ngIf="data.shapeHide"><img src="assets/img/shape/tree-shape3.png" alt="image"></div>
</div>