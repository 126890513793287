<div class="courses-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Go at your own pace</span>
            <h2>The World’s Largest Selection Of Courses</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>
        <div class="row" *ngIf="data">
            <div class="col-lg-6 col-md-12" *ngFor="let content of data.slice(0, 6)">
                <div class="single-courses-item without-box-shadow">
                    <div class="row">
                        <div class="col-lg-4 col-md-4">
                            <div class="courses-image" style="background-image: url({{content.image.url}});">
                                <img [src]="content.image.url" alt="image">
                                <a
                                    [routerLink]="['/course', content.slug]"
                                    class="link-btn"
                                ></a>
                            </div>
                        </div>
                        <div class="col-lg-8 col-md-8">
                            <div class="courses-content">
                                <span class="price">${{content.price}}</span>
                                <h3>
                                    <a
                                        [routerLink]="['/course', content.slug]"
                                    >
                                        {{content.title}}
                                    </a>
                                </h3>
                                <ul class="courses-content-footer d-flex justify-content-between align-items-center">
                                    <li *ngIf="content.numberOfLesson"><i class='flaticon-agenda'></i> {{content.numberOfLesson}} Lessons</li>
                                    <li *ngIf="content.numberOfStudent"><i class='flaticon-people'></i> {{content.numberOfStudent}} Students</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-12 col-md-12">
                <div class="courses-info">
                    <a routerLink="/courses-1" class="default-btn"><i class="flaticon-user"></i>View All Courses<span></span></a>
                    <p>Get into details now?​ <a routerLink="/courses-1">PM Master’s Program</a></p>
                </div>
            </div>
        </div>
    </div>
</div>