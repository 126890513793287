<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>My Course</li>
            </ul>
            <h2>My Course</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape/shape8.svg" alt="image"></div>
</div>

<div *ngIf="!isLoggedIn" class="login-area ptb-100">
    <div class="container text-center">
        <a
            routerLink="/profile-authentication"
            class="default-btn"
        >
            <i class="flaticon-user"></i>
            Please login to continue
            <span></span>
        </a>
    </div>
</div>

<div *ngIf="isLoggedIn" class="videos-area ptb-100">
    <div class="container">
        <div class="videos-lists">
            <div *ngIf="data || userOrders">
                <ng-container *ngFor="let item of userOrders.slice(0, 1);">
                    <ng-container *ngFor="let text of item.courses;">
                        <div *ngFor="let content of data;">
                            <div *ngIf="text.title == content.title">
                                <div class="single-video row">
                                    <div class="col-lg-6" *ngFor="let item of content.course_videos;">
                                        <h4>{{item.title}}</h4>
                                        <video controls>
                                            <source [src]="item.video.url" type="video/mp4">
                                            Your browser does not support HTML video.
                                        </video>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
            </div>
        </div>
    </div>
</div>