import {Component} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {loadStripe} from "@stripe/stripe-js";
import {async} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";

@Component({
    selector: 'app-sucess',
    templateUrl: './sucess.component.html',
    styleUrls: ['./sucess.component.scss']
})
export class SucessComponent {
    private API_URL = environment.API_URL;

    constructor(private router: ActivatedRoute, private http: HttpClient) {
        this.router.queryParamMap.subscribe((p: any) => {
            this.updateCoursePurchase(p.params['session_id']);
        })
    }

    updateCoursePurchase(sessionId) {
        let url = `${this.API_URL}/payments/id/success?id=` + sessionId;
        this.http.get(url).subscribe((data: any) => {
            console.log(data)
        });
    }
}
