<div class="courses-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Go At Your Own Pace</span>
            <h2>Our Popular Courses</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>
        <div class="courses-slides">
            <owl-carousel-o [options]="coursesSlides" *ngIf="data">
                <ng-template carouselSlide *ngFor="let content of data;">
                    <div class="single-courses-box">
                        <div class="courses-image">
                            <a
                                [routerLink]="['/course', content.slug]"
                                class="d-block image"
                            >
                                <img [src]="content.image.url" alt="image">
                            </a>
                            <div class="price shadow">${{content.price}}</div>
                            <button
                                type="submit"
                                class="default-btn"
                                (click)="addToCart(content)"
                            >
                                Add To Cart
                                <span></span>
                            </button>
                        </div>
                        <div class="courses-content">
                            <div class="course-author d-flex align-items-center">
                                <img src="assets/img/avatar.jpg" class="rounded-circle" alt="image">
                                <span *ngIf="content.admin_user">{{content.admin_user.firstname}} {{content.admin_user.lastname}}</span>
                            </div>
                            <h3>
                                <a
                                    [routerLink]="['/course', content.slug]"
                                >
                                    {{content.title}}
                                </a>
                            </h3>
                            <div markdown ngPreserveWhitespaces>
                            {{content.shortDescription}}
                        </div>
                            <ul class="courses-box-footer d-flex justify-content-between align-items-center">
                                <li *ngIf="content.numberOfLesson"><i class='flaticon-agenda'></i> {{content.numberOfLesson}} Lessons</li>
                                <li *ngIf="content.numberOfStudent"><i class='flaticon-people'></i> {{content.numberOfStudent}} Students</li>
                            </ul>
                        </div>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
        <div class="courses-info">
            <p>Get the most dedicated consultation for your life-changing course. Earn a certification for your effort and passion <a routerLink="/profile-authentication">Join Free Now</a>.</p>
        </div>
    </div>
</div>
