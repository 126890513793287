<div class="page-title-area">
    <div class="container">
        <div class="page-title-content" *ngIf="blogData">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>{{blogData.pageBannerTitle}}</li>
            </ul>
            <h2>{{blogData.pageBannerTitle}}</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape/shape8.svg" alt="image"></div>
</div>

<div class="blog-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12" *ngIf="data">
                <div class="row">
                    <div class="col-lg-12 col-md-6" *ngFor="let content of data | paginate: { itemsPerPage: 4, currentPage: blogGrid }">
                        <div class="single-blog-post">
                            <div class="post-image">
                                <a
                                    [routerLink]="['/blog', content.slug]"
                                    class="d-block"
                                >
                                    <img [src]="content.image.url" alt="image">
                                </a>
                            </div>
                            <div class="post-content">
                                <a
                                    [routerLink]="['/category', content.blog_category.slug]"
                                    class="category"
                                >
                                    {{content.blog_category.title}}
                                </a>
                                <h3>
                                    <a 
                                        [routerLink]="['/blog', content.slug]"
                                    >
                                        {{content.title}}
                                    </a>
                                </h3>
                                <span class="date"><i class='flaticon-calendar'></i> {{content.published_at | date}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12" *ngIf="data?.length > 4">
                        <div class="pagination-area text-center">
                            <pagination-controls previousLabel="" nextLabel="" (pageChange)="blogGrid = $event"></pagination-controls>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <aside class="widget-area">
                    <div class="widget widget_search">
                        <h3 class="widget-title">Search</h3>
                        <div class="search-form" [class.active]="classSearchApplied">
                            <input type="text" [(ngModel)]="term" class="search-field" placeholder="Search blog posts..." (click)="togglSearcheClass()">
                            <button type="submit">
                                <i class="bx bx-search-alt"></i>
                            </button>
                            <div class="blog-lists" *ngIf="data">
                                <div class="list-group-item" *ngFor="let content of data | filter : term">
                                    <div class="row align-items-center" (click)="togglSearcheClass()">
                                        <div class="col-lg-3 col-md-2 col-3">
                                            <a 
                                                [routerLink]="['/blog', content.slug]"
                                                class="blog-img"
                                            >
                                                <img [src]="content.image.url" alt="image">
                                            </a>
                                        </div>
                                        <div class="col-lg-9 col-md-10 col-9">
                                            <div class="info">
                                                <h3>
                                                    <a 
                                                        [routerLink]="['/blog', content.slug]"
                                                    >
                                                        {{content.title}}
                                                    </a>
                                                </h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="widget widget_edva_posts_thumb" *ngIf="data">
                        <h3 class="widget-title">Recent Posts</h3>
                        <article class="item" *ngFor="let content of data.slice(0, 3)">
                            <a
                                [routerLink]="['/blog', content.slug]"
                                class="thumb"
                            >
                                <span class="fullimage cover" role="img" style="background-image: url({{content.image.url}});"></span>
                            </a>
                            <div class="info">
                                <span>{{content.published_at | date}}</span>
                                <h4 class="title usmall">
                                    <a 
                                        [routerLink]="['/blog', content.slug]"
                                    >
                                        {{content.title}}
                                    </a>
                                </h4>
                            </div>
                            <div class="clear"></div>
                        </article>
                    </div>
                    <div class="widget widget_categories" *ngIf="blogCategoriesdata">
                        <h3 class="widget-title">Categories</h3>
                        <ul>
                            <li *ngFor="let content of blogCategoriesdata.slice(0, 10);">
                                <a
                                    [routerLink]="['/category', content.slug]"
                                    class="d-block"
                                >
                                    {{content.title}}
                                    <span class="post-count">({{content.blog_posts.length}})</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="widget widget_tag_cloud" *ngIf="blogTagsdata">
                        <h3 class="widget-title">Tags</h3>
                        <div class="tagcloud">
                            <a
                                *ngFor="let content of blogTagsdata;"
                                [routerLink]="['/tag', content.slug]"
                            >
                                {{content.tag}}
                                <span class="tag-link-count">({{content.blog_posts.length}})</span>
                            </a>
                        </div>
                    </div>
                </aside>
            </div>
        </div>
    </div>
</div>