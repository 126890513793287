<div class="courses-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Go at your own pace</span>
            <h2>Our Recent Packages</h2>
            <p>Explore all of our packages and pick your suitable ones to enroll and start learning with us! We ensure that you will never regret it!</p>
        </div>
        <div class="row justify-content-center" *ngIf="data">
            <div class="col-lg-4 col-md-6" *ngFor="let content of data.slice(0, 3)">
                <div class="single-courses-box without-boxshadow">
                    <div class="courses-image">
                        <a
                            [routerLink]="['/course', content.slug]"
                            class="d-block image"
                        >
                            <img [src]="content.image.url" alt="image">
                        </a>
                        <div class="price shadow"> ${{content.price}}</div>
                        <button
                            type="submit"
                            class="default-btn"
                            (click)="addToCart(content)"
                        >
                            Add To Cart
                            <span></span>
                        </button>
                    </div>
                    <div class="courses-content">
                        <div class="course-author d-flex align-items-center">
<!--                            <img src="assets/img/avatar.jpg" class="rounded-circle" alt="image">-->
<!--                            <span *ngIf="content.admin_user">{{content.admin_user.firstname}} {{content.admin_user.lastname}}</span>-->
                        </div>
                        <h3>
                            <a
                                [routerLink]="['/course', content.slug]"
                            >
                                {{content.title}}
                            </a>
                        </h3>
<!--                        <p>{{content.shortDescription}}</p>-->
                        <div markdown ngPreserveWhitespaces>
                            {{content.shortDescription}}
                        </div>
                        <ul class="courses-box-footer d-flex justify-content-between align-items-center">
                            <li *ngIf="content.totalCourseDuration"><i class='flaticon-agenda'></i> {{content.totalCourseDuration}}</li>
                            <li *ngIf="content.numberOfStudent"><i class='flaticon-people'></i> {{content.numberOfStudent}} Students</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-12 col-md-12">
                <div class="courses-info">
                    <a routerLink="/courses-2" class="default-btn"><i class="flaticon-user"></i>View All Packages<span></span></a>
                    <p>Have any questions or concerns? <a routerLink="/contact">Contact Us</a></p>
                </div>
            </div>
        </div>
    </div>
</div>
