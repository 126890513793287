<div class="why-choose-us-area ptb-100" *ngIf="data">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="why-choose-us-image">
                    <img [src]="data.image.url" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="why-choose-us-content">
                    <span class="sub-title">{{data.subtitle}}</span>
                    <h2>{{data.title}}</h2>
                    <p>{{data.paragraph}}</p>
                    <ul class="features-list">
                        <li *ngFor="let item of data.allFeatures">
                            <span>
                                <i class="{{item.icon}}"></i> {{item.title}}
                            </span>
                        </li>
                    </ul>
                    <a routerLink="{{data.buttonLink}}" class="default-btn">
                        <i class="{{data.buttonIcon}}"></i>
                        {{data.buttonText}}
                        <span></span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>