<div class="kindergarten-main-banner" *ngIf="data">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="kindergarten-banner-content">
                    <div class="image">
                        <img [src]="data.topImage.url" alt="image">
                    </div>
                    <h1>{{data.title1}} <span>{{data.title2}}</span> {{data.title3}}</h1>
                    <p>{{data.desc}}</p>
                    <a routerLink="{{data.buttonLink}}" class="default-btn-style-two"><i class="{{data.buttonIcon}}"></i>{{data.buttonText}}</a>
                    <div class="circle-shape"><img src="assets/img/shape/circle.png" alt="image"></div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="kindergarten-banner-image">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6" *ngFor="let item of data.imagesList.slice(0, 4)">
                            <div class="image">
                                <img [src]="item.image.url" alt="image">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="kindergarten-shape1" *ngIf="data.shapeHide"><img src="assets/img/kindergarten-shape/k-shape1.png" alt="image"></div>
    <div class="kindergarten-shape2" *ngIf="data.shapeHide"><img src="assets/img/kindergarten-shape/k-shape2.png" alt="image"></div>
    <div class="kindergarten-shape3" *ngIf="data.shapeHide"><img src="assets/img/kindergarten-shape/k-shape3.png" alt="image"></div>
    <div class="kindergarten-shape4" *ngIf="data.shapeHide"><img src="assets/img/kindergarten-shape/k-shape4.png" alt="image"></div>
    <div class="kindergarten-shape5" *ngIf="data.shapeHide"><img src="assets/img/kindergarten-shape/k-shape5.png" alt="image"></div>
    <div class="kindergarten-shape6" *ngIf="data.shapeHide"><img src="assets/img/kindergarten-shape/k-shape6.png" alt="image"></div>
</div>