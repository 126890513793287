<div class="page-title-area">
    <div class="container">
        <div class="page-title-content" *ngIf="blogData">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>{{blogData.pageBannerTitle}}</li>
            </ul>
            <h2>{{blogData.pageBannerTitle}}</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape/shape8.svg" alt="image"></div>
</div>

<div class="blog-area pt-100 pb-70" *ngIf="data">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-3 col-md-6" *ngFor="let content of data | paginate: { itemsPerPage: 8, currentPage: blogGrid }">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a
                            [routerLink]="['/blog', content.slug]"
                            class="d-block"
                        >
                            <img [src]="content.image.url" alt="image">
                        </a>
                    </div>
                    <div class="post-content">
                        <a
                            [routerLink]="['/category', content.blog_category.slug]"
                            class="category"
                        >
                            {{content.blog_category.title}}
                        </a>
                        <h3>
                            <a 
                                [routerLink]="['/blog', content.slug]"
                            >
                                {{content.title}}
                            </a>
                        </h3>
                        <span class="date"><i class='flaticon-calendar'></i> {{content.published_at | date}}</span>
                    </div>
                </div>
            </div>
            <div class="col-lg-12 col-md-12" *ngIf="data?.length > 6">
                <div class="pagination-area text-center">
                    <pagination-controls previousLabel="" nextLabel="" (pageChange)="blogGrid = $event"></pagination-controls>
                </div>
            </div>
        </div>
    </div>
</div>