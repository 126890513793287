<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Products</li>
            </ul>
            <h2>Shop</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape/shape8.svg" alt="image"></div>
</div>

<div class="products-area pt-100 pb-70">
    <div class="container" *ngIf="data">
        <div class="edva-grid-sorting row align-items-center">
            <div class="col-lg-6 col-md-6 result-count">
                <p>We found <span class="count">{{data.length}}</span> products available for you</p>
            </div>
            <div class="col-lg-6 col-md-6 ordering">
                <div class="select-box">
                    <label>Sort By:</label>
                    <select>
                        <option>Default</option>
                        <option>Popularity</option>
                        <option>Latest</option>
                        <option>Price: low to high</option>
                        <option>Price: high to low</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6" *ngFor="let content of data | paginate: { itemsPerPage: 6, currentPage: shopGrid }">
                <div class="single-products-box">
                    <div class="products-image">
                        <a
                            [routerLink]="['/product', content.slug]"
                        >
                            <img [src]="content.image.url" class="main-image" alt="image">
                        </a>
                    </div>
                    <div class="products-content">
                        <h3>
                            <a
                                [routerLink]="['/product', content.slug]"
                            >
                                {{content.title}}
                            </a>
                        </h3>
                        <div class="price">
                            <span class="new-price">${{content.price}}</span>
                        </div>
                        <button
                            class="add-to-cart"
                            (click)="addToCart(content)"
                            *ngIf="content.availability"
                        >
                            Add to Cart
                        </button>
                        <button
                            class="add-to-cart out"
                            *ngIf="!content.availability"
                        >
                            Out of Stock
                        </button>
                    </div>
                </div>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12" *ngIf="data?.length > 6">
                <div class="pagination-area text-center">
                    <pagination-controls previousLabel="" nextLabel="" (pageChange)="shopGrid = $event"></pagination-controls>
                </div>
            </div>
        </div>
    </div>
</div>