<div class="blog-area ptb-100">
    <div class="container">
        <div class="section-title" *ngIf="blogData">
            <span class="sub-title">{{blogData.subtitle}}</span>
            <h2>{{blogData.title}}</h2>
            <p>{{blogData.paragraph}}</p>
        </div>
        <div class="blog-slides" *ngIf="data">
            <owl-carousel-o [options]="blogSlides">
                <ng-template carouselSlide *ngFor="let content of data.slice(0, 6)">
                    <div class="single-blog-post-box">
                        <div class="post-image">
                            <a
                                [routerLink]="['/blog', content.slug]"
                                class="d-block"
                            >
                                <img [src]="content.image.url" alt="image">
                            </a>
                        </div>
                        <div class="post-content">
                            <a
                                [routerLink]="['/category', content.blog_category.slug]"
                                class="category"
                            >
                                {{content.blog_category.title}}
                            </a>
                            <h3>
                                <a 
                                    [routerLink]="['/blog', content.slug]"
                                >
                                    {{content.title}}
                                </a>
                            </h3>
                            <span class="date"><i class='flaticon-calendar'></i> {{content.published_at | date}}</span>
                        </div>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
        <div class="blog-post-info">
            <p>Get into details now?​ <a routerLink="/blog-4">View all posts</a></p>
        </div>
    </div>
</div>