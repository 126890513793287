<div *ngIf="data">
 
    <div class="page-title-area">
        <div class="container">
            <div class="page-title-content">
                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li>FAQ's</li>
                </ul>
                <h2>{{data.pageBannerTitle}}</h2>
            </div>
        </div>
        <div class="shape9"><img src="assets/img/shape/shape8.svg" alt="image"></div>
    </div>

    <div class="faq-area ptb-100">
        <div class="container">
            <div class="faq-accordion">
                <div class="accordion-section" *ngFor="let content of data.faq">
                    <div class="accordion-header" (click)="toggleSection(content.id)" [ngClass]="{ 'open': isSectionOpen(content.id) }">
                        {{content.question}}
                    </div>
                    <div class="accordion-content" [ngClass]="{ 'open': isSectionOpen(content.id) }">
                        <div markdown ngPreserveWhitespaces>
                            {{content.answer}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>

<div class="subscribe-area bg-f9f9f9 ptb-100">
    <div class="container">
        <app-subscribe></app-subscribe>
    </div>
    <div class="shape4"><img src="assets/img/shape/shape4.png" alt="image"></div>
    <div class="shape13"><img src="assets/img/shape/shape12.png" alt="image"></div>
    <div class="shape14"><img src="assets/img/shape/shape13.png" alt="image"></div>
    <div class="shape15"><img src="assets/img/shape/shape14.png" alt="image"></div>
</div>