<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Courses</li>
            </ul>
            <h2>Courses</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape/shape8.svg" alt="image"></div>
</div>

<div class="courses-area pt-100 pb-70 bg-f5f7fa" *ngIf="data">
    <div class="container">
        <div class="edva-grid-sorting row align-items-center">
            <div class="col-lg-6 col-md-6 result-count">
                <p>We found <span class="count">{{data.length}}</span> courses available for you</p>
            </div>
            <div class="col-lg-6 col-md-6 ordering">
                <div class="select-box">
                    <label>Sort By:</label>
                    <select>
                        <option>Default</option>
                        <option>Popularity</option>
                        <option>Latest</option>
                        <option>Price: low to high</option>
                        <option>Price: high to low</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6 col-md-12" *ngFor="let content of data | paginate: { itemsPerPage: 12, currentPage: coursesGrid }">
                <div class="single-courses-item">
                    <div class="row">
                        <div class="col-lg-4 col-md-4">
                            <div class="courses-image" style="background-image: url({{content.image.url}});">
                                <img [src]="content.image.url" alt="image">
                                <a
                                    [routerLink]="['/course', content.slug]"
                                    class="link-btn"
                                ></a>
                            </div>
                        </div>
                        <div class="col-lg-8 col-md-8">
                            <div class="courses-content">
                                <span class="price">${{content.price}}</span>
                                <h3>
                                    <a
                                        [routerLink]="['/course', content.slug]"
                                    >
                                        {{content.title}}
                                    </a>
                                </h3>
                                <ul class="courses-content-footer d-flex justify-content-between align-items-center">
                                    <li *ngIf="content.numberOfLesson"><i class='flaticon-agenda'></i> {{content.numberOfLesson}} Lessons</li>
                                    <li *ngIf="content.numberOfStudent"><i class='flaticon-people'></i> {{content.numberOfStudent}} Students</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-12 col-md-12" *ngIf="data?.length > 12">
                <div class="pagination-area text-center">
                    <pagination-controls previousLabel="" nextLabel="" (pageChange)="coursesGrid = $event"></pagination-controls>
                </div>
            </div>
        </div>
    </div>
</div>