<div *ngIf="data">
    <div *ngFor="let content of data;">

        <div class="page-title-area">
            <div class="container">
                <div class="page-title-content">
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Blog</li>
                        <li>Category: {{content.tag}}</li>
                    </ul>
                    <h2>Category: {{content.tag}}</h2>
                </div>
            </div>
            <div class="shape9"><img src="assets/img/shape/shape8.svg" alt="image"></div>
        </div>

        <div class="blog-area pt-100 pb-70">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-4 col-md-6" *ngFor="let text of content.blog_posts | paginate: { itemsPerPage: 9, currentPage: blogGrid }">
                        <div class="single-blog-post">
                            <div class="post-image">
                                <a
                                    [routerLink]="['/blog', text.slug]"
                                    class="d-block"
                                >
                                    <img [src]="text.image.url" alt="image">
                                </a>
                            </div>
                            <div class="post-content">
                                <h3>
                                    <a 
                                        [routerLink]="['/blog', text.slug]"
                                    >
                                        {{text.title}}
                                    </a>
                                </h3>
                                <span class="date"><i class='flaticon-calendar'></i> {{text.published_at | date}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12" *ngIf="content.blog_posts?.length <= 0">
                        <div class="text-center mb-30">
                            <p>No blog posts for you in this tag.</p>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12" *ngIf="content.blog_posts?.length > 9">
                        <div class="pagination-area text-center">
                            <pagination-controls previousLabel="" nextLabel="" (pageChange)="blogGrid = $event"></pagination-controls>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>