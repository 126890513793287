<div class="container" *ngIf="data">
    <div class="section-title">
        <span class="sub-title">{{data.subtitle}}</span>
        <h2>{{data.title}}​</h2>
        <p>{{data.paragraph}}</p>
    </div>
    <div class="row justify-content-center">
        <div class="col-lg-4 col-sm-6 col-md-6" *ngFor="let content of data.featuresCard">
            <div class="single-features-box without-padding">
                <div class="icon">
                    <i class="{{content.icon}}"></i>
                </div>
                <h3>{{content.title}}</h3>
                <p>{{content.paragraph}}</p>
                <a routerLink="{{content.link}}" class="link-btn">{{content.linkText}}</a>
            </div>
        </div>
    </div>
</div>