<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Courses</li>
            </ul>
            <h2>Courses</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape/shape8.svg" alt="image"></div>
</div>

<div class="courses-area pt-100 pb-70" *ngIf="data">
    <div class="container">
        <div class="edva-grid-sorting row align-items-center">
            <div class="col-lg-6 col-md-6 result-count">
                <p>We found <span class="count">{{data.length}}</span> courses available for you</p>
            </div>
            <div class="col-lg-6 col-md-6 ordering">
                <div class="select-box">
                    <label>Sort By:</label>
                    <select>
                        <option>Default</option>
                        <option>Popularity</option>
                        <option>Latest</option>
                        <option>Price: low to high</option>
                        <option>Price: high to low</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6" *ngFor="let content of data | paginate: { itemsPerPage: 12, currentPage: coursesGrid }">
                <div class="single-courses-item-box">
                    <div class="courses-image">
                        <a
                            [routerLink]="['/course', content.slug]"
                            class="d-block image"
                        >
                            <img [src]="content.image.url" alt="image">
                        </a>
                        <div class="price shadow">${{content.price}}</div>
                        <button 
                            type="submit" 
                            class="default-btn"
                            (click)="addToCart(content)"
                        >
                            Add To Cart
                            <span></span>
                        </button>
                    </div>
                    <div class="courses-content">
                        <h3>
                            <a
                                [routerLink]="['/course', content.slug]"
                            >
                                {{content.title}}
                            </a>
                        </h3>
                        <div class="course-author d-flex align-items-center justify-content-center">
                            <img src="assets/img/avatar.jpg" class="rounded-circle" alt="image">
                            <span *ngIf="content.admin_user">{{content.admin_user.firstname}} {{content.admin_user.lastname}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-12 col-md-12" *ngIf="data?.length > 12">
                <div class="pagination-area text-center">
                    <pagination-controls previousLabel="" nextLabel="" (pageChange)="coursesGrid = $event"></pagination-controls>
                </div>
            </div>
        </div>
    </div>
</div>