import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../user.service';
import { CourseCartService } from '../../../courseCart.service';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { NotifierService } from 'angular-notifier';
import {loadStripe} from "@stripe/stripe-js";

@Component({
    selector: 'app-courses-checkout',
    templateUrl: './courses-checkout.component.html',
    styleUrls: ['./courses-checkout.component.scss']
})
export class CoursesCheckoutComponent implements OnInit {

    private readonly notifier: NotifierService;
    private API_URL = environment.API_URL;
    private stripe_publish_key = environment.stripe_publish_key;
    isLoggedIn = this.userService.isLoggedIn();
    courses = this.coursesCartService.getItems();
    total = this.coursesCartService.getTotal();
    userData = this.userService.getUser();
    paymentHandler:any = null;

    constructor(
        private coursesCartService: CourseCartService,
        private userService: UserService,
        private http: HttpClient,
        notifierService: NotifierService,
    ) {
        this.notifier = notifierService;
    }

    ngOnInit(): void {
        this.invokeStripe();
    }

    invokeStripe() {
        if(!window.document.getElementById('stripe-script')) {
            let script = window.document.createElement("script");
            script.id = "stripe-script";
            script.type = "text/javascript";
            script.src = "https://checkout.stripe.com/checkout.js";
            window.document.body.appendChild(script);
        }
    }

    makePayment1(amount:any) {
        const paymentHandler = (<any>window).StripeCheckout.configure({
            key: this.stripe_publish_key,
            locale: 'auto',
            // image:
            token: (token: any) => {
                debugger;
                console.log(token);
                // alert('Stripe token generated!');

                let checkData = this.courses;
                let checkCourses = this.courses;
                const cartCourses = {
                    customer: checkData,
                    courses: checkCourses,
                    total: this.total,
                    user: this.userData
                };
                let url = `${this.API_URL}/orders`;
                let me = `${this.API_URL}/users/${this.userData.id}`;

                this.http
                .post<any>(url, { courses: cartCourses })
                .subscribe((response) => {
                    this.notifier.notify('success', 'Checkout Successful!');
                });

                this.http
                .get<any>(me, {
                    headers: { 'Authorization': `Bearer ${this.isLoggedIn}` }
                })
                .subscribe((response) => {
                    localStorage.setItem('edva_users', JSON.stringify(response));
                });
                this.courses = this.coursesCartService.clearCart();
            }
        });
        paymentHandler.open({
            name: 'Edva- NEW test',
            amount: amount * 100,

        });
    }

    makePayment(amount:any){
        debugger;
        let checkData = this.courses;
        let checkCourses = this.courses;
        const cartCourses = {
            customer: checkData,
            courses: checkCourses,
            total: this.total,
            user: this.userData
        };
        let checkoutUrl = `${this.API_URL}/payments/checkout`;

        this.http
            .post<any>(checkoutUrl,  cartCourses)
            .subscribe(async (res :any)=>{
                let stripe = await loadStripe(this.stripe_publish_key);
                stripe?.redirectToCheckout({
                        sessionId: res.id
                    },

                )
            });

    }

}
//https://www.youtube.com/watch?v=qiRnyI4KGwg&ab_channel=CodingShiksha
