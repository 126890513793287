<div class="about-area ptb-100" *ngIf="data">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="kindergarten-about-image">
                    <div class="main-image">
                        <img [src]="data.image1.url" alt="image">
                        <img [src]="data.image2.url" alt="image">
                    </div>
                    <div class="shape"><img src="assets/img/shape/kite1.png" alt="image"></div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <span class="sub-title">{{data.subtitle}}</span>
                    <h2 class="font-weight-black">{{data.title}}</h2>
                    <p>{{data.subText}}</p>
                    <p>{{data.paragraph}}</p>
                    <ul class="about-list">
                        <li *ngFor="let item of data.featuresList">
                            <span>
                                <i class="{{item.icon}}"></i> {{item.title}}
                            </span>
                        </li>
                    </ul>
                    <a routerLink="{{data.buttonLink}}" class="default-btn">
                        <i class="{{data.buttonIcon}}"></i>
                        {{data.buttonText}}
                        <span></span>
                    </a>
                </div>
            </div>
        </div>
    </div>
    <div class="kindergarten-shape7"><img src="assets/img/kindergarten-shape/k-shape7.png" alt="image"></div>
    <div class="kindergarten-shape8"><img src="assets/img/kindergarten-shape/k-shape8.png" alt="image"></div>
</div>