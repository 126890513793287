<div class="advisor-area bg-f9f9f9 pt-100">
    <div class="container">
        <div class="section-title">
<!--            <span class="sub-title">Instructor</span>-->
            <h2>Instructor</h2>
            <p>Get acquainted with our some of the expert instructors, actively guiding and supporting your journey toward a flourishing tech career.</p>
        </div>
        <div class="advisor-slides">
            <owl-carousel-o [options]="advisorSlides">
                <ng-template carouselSlide>
                    <div class="single-advisor-box">
                        <div class="row align-items-center">
                            <div class="col-lg-4 col-md-12">
                                <div class="advisor-image">
                                    <img src="assets/img/advisor/img1.jpg" alt="image">
                                </div>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <div class="advisor-content">
                                    <h3><a routerLink="/profile">William James</a></h3>
                                    <span class="sub-title">Software Development Expert</span>
                                    <p>William, a software development veteran with 9 years of experience, mentors and guides students to successful job placements. His personalized approach and industry connections ensure tailored support for every aspiring developer.</p>
<!--                                    <ul class="social-link">-->
<!--                                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>-->
<!--                                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>-->
<!--                                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>-->
<!--                                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>-->
<!--                                    </ul>-->
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-advisor-box">
                        <div class="row align-items-center">
                            <div class="col-lg-4 col-md-12">
                                <div class="advisor-image">
                                    <img src="assets/img/advisor/img2.jpg" alt="image">
                                </div>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <div class="advisor-content">
                                    <h3><a routerLink="/profile">Sarah Taylor</a></h3>
                                    <span class="sub-title">Quality Assurance Expert</span>
                                    <p>Sarah, a seasoned professional with 12 years of experience in Quality Assurance. With a passion for teaching, she's not only honed her craft but also guided numerous students to secure jobs in QA roles. Sarah brings a wealth of expertise to ensure top-quality solutions and empower others to excel in the field.</p>
<!--                                    <ul class="social-link">-->
<!--                                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>-->
<!--                                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>-->
<!--                                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>-->
<!--                                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>-->
<!--                                    </ul>-->
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-advisor-box">
                        <div class="row align-items-center">
                            <div class="col-lg-4 col-md-12">
                                <div class="advisor-image">
                                    <img src="assets/img/advisor/img3.jpg" alt="image">
                                </div>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <div class="advisor-content">
                                    <h3><a routerLink="/profile">James Andy</a></h3>
                                    <span class="sub-title">Business Analyst Expert</span>
                                    <p>Meet James, a seasoned Business Analyst Expert with 6 years of teaching experience. He's not just a mentor; he's a career guide, having successfully placed countless students in coveted job roles. With James by your side, mastering the intricacies of business analysis is not just a goal; it's an inevitable outcome.</p>
<!--                                    <ul class="social-link">-->
<!--                                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>-->
<!--                                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>-->
<!--                                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>-->
<!--                                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>-->
<!--                                    </ul>-->
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
</div>
