<div class="online-platform-area pt-100" *ngIf="data">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="online-platform-image">
                    <img [src]="data.image.url" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="online-platform-content">
                    <span class="sub-title">{{data.subtitle}}</span>
                    <h2>{{data.title}}</h2>
                    <p>{{data.paragraph}}</p>
                    <a routerLink="{{data.buttonLink}}" class="default-btn">
                        <i class="{{data.buttonIcon}}"></i>
                        {{data.buttonText}}
                        <span></span>
                    </a>
                </div>
            </div>
        </div>
    </div>
    <div class="shape23"><img src="assets/img/shape/shape23.png" alt="image"></div>
</div>