<div class="feedback-area bg-6dbbbd pt-100 pb-70" *ngIf="data">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">{{data.subtitle}}</span>
            <h2 class="font-weight-black">{{data.title}}</h2>
            <p>{{data.paragraph}}</p>
        </div>
    </div>
    <div class="container-fluid">
        <div class="feedback-slides-three">
            <owl-carousel-o [options]="feedbackSlides">
                <ng-template carouselSlide *ngFor="let content of data.allFeedback;">
                    <div class="single-kindergarten-feedback-item">
                        <div class="content">
                            <img src="assets/img/shape/feedback-shape.png" alt="image">
                            <p>{{content.feedbackQuote}}</p>
                        </div>
                        <div class="client-info">
                            <img [src]="content.authorImage.url" alt="image">
                            <h3 class="font-weight-black">{{content.authorName}}</h3>
                            <span>{{content.authorDesignation}}</span>
                        </div>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
    <div class="kindergarten-shape13"><img src="assets/img/kindergarten-shape/k-shape13.png" alt="image"></div>
    <div class="kindergarten-shape14"><img src="assets/img/kindergarten-shape/k-shape14.png" alt="image"></div>
</div>