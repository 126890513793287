<div class="funfacts-and-feedback-area ptb-100" *ngIf="data" style="background-image: url({{data.bgImage.url}});">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="feedback-content">
                    <span class="sub-title">{{data.subtitle}}</span>
                    <h2>{{data.title}}</h2>
                    <p>{{data.paragraph}}</p>
                    <div class="feedback-slides">
                        <owl-carousel-o [options]="feedbackSlides">
                            <ng-template carouselSlide *ngFor="let content of data.allFeedback">
                                <div class="single-feedback-item">
                                    <p>{{content.feedbackQuote}}</p>
                                    <div class="client-info d-flex align-items-center">
                                        <img [src]="content.authorImage.url" class="rounded-circle" alt="image">
                                        <div class="title">
                                            <h3>{{content.authorName}}</h3>
                                            <span>{{content.authorDesignation}}</span>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </owl-carousel-o>
                    </div>
                    <div class="feedback-info">
                        <p>Not a member yet?​ <a routerLink="/profile-authentication">Register now</a></p>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="funfacts-list">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6" *ngFor="let content of data.funfacts.slice(0, 4)">
                            <div class="single-funfacts-box">
                                <h3><span [countUp]="content.number">00</span></h3>
                                <p>{{content.title}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-12 col-md-12">
                <app-video></app-video>
            </div>
        </div>
    </div>
    <div class="shape2" *ngIf="data.shapeHide"><img src="assets/img/shape/shape2.png" alt="image"></div>
    <div class="shape3" *ngIf="data.shapeHide"><img src="assets/img/shape/shape3.png" alt="image"></div>
    <div class="shape4" *ngIf="data.shapeHide"><img src="assets/img/shape/shape4.png" alt="image"></div>
    <div class="shape9" *ngIf="data.shapeHide"><img src="assets/img/shape/shape8.svg" alt="image"></div>
</div>