<div class="health-coaching-banner-area" *ngIf="data">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="health-coaching-banner-image">
                    <img [src]="data.mainImage.url" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="health-coaching-banner-content">
                    <h1 class="playfair-display-font">{{data.title}}</h1>
                    <p>{{data.desc}}</p>
                    <div class="btn-box">
                        <div class="d-flex align-items-center">
                            <a routerLink="{{data.buttonLink}}" class="default-btn"><i class="{{data.buttonIcon}}"></i>{{data.buttonText}}<span></span></a>
                            <img [src]="data.signature.url" class="signature" alt="image">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="health-coaching-shape1"><img [src]="data.leftBottomImage.url" alt="image"></div>
    <div class="health-coaching-shape2"><img [src]="data.topRightImage.url" alt="image"></div>
    <div class="health-coaching-shape3"><img [src]="data.rightBottomImage.url" alt="image"></div>
    <div class="health-coaching-shape4" *ngIf="data.shapeHide"><img src="assets/img/shape/health-coaching-shape4.png" alt="image"></div>
    <div class="health-coaching-shape5" *ngIf="data.shapeHide"><img src="assets/img/shape/health-coaching-shape5.png" alt="image"></div>
    <div class="health-coaching-shape6" *ngIf="data.shapeHide"><img src="assets/img/shape/health-coaching-shape6.png" alt="image"></div>
    <div class="health-coaching-shape7" *ngIf="data.shapeHide"><img src="assets/img/shape/health-coaching-shape7.png" alt="image"></div>
    <div class="divider"></div>
</div>